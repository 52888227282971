import React, { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
  Text
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLogin, checkIsAlreadyLoggedIn } from '../store/slices/user';
import { useHistory } from 'react-router-dom';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';

// KEYFRAMES FOR BACKGROUND GRADIENT
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// KEYFRAMES FOR FADE-IN ANIMATION
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// PAGE CONTAINER: full viewport height + flex centering
const PageContainer = styled.div`
  /* Start invisible for a clean fade-in */
  opacity: 0;
  transform: translateY(10px);
  /* We'll run two animations: fadeIn (once, forwards) + the infinite gradient shift */
  animation: ${fadeIn} 1s ease forwards, ${gradientAnimation} 15s ease infinite;

  height: 100vh; /* Ensures the container spans the full screen */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;

  /* Animated gradient background */
  background: linear-gradient(135deg, #2c3e50, #4ca1af) !important;
  background-size: 400% 400%;
`;

// HEADING
const Heading = styled.h1`
  color: white;
  margin-bottom: 4px;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards;
  animation-delay: 0.2s;
`;

// SUBHEADING
const SubHeading = styled.p`
  margin-bottom: 24px;
  color: lightgrey;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards;
  animation-delay: 0.3s;
`;

// FORM CARD: translucent background, fade-in
const FormCard = styled.div`
  opacity: 0;
  transform: translateY(10px);
  animation: ${fadeIn} 0.8s ease forwards;
  animation-delay: 0.4s;

  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px);
  padding: 2rem 2rem 0;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  margin: 0 auto; /* Ensures no left/right offset */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

// FORM WRAPPER
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// INPUT CONTAINER
const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

// OPTIONAL MICRO-INTERACTION FOR BUTTONS
const StyledButton = styled(Button)`
  && {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    &:hover {
      transform: scale(1.03);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
  }
`;

// TEXTFIELD FOCUS SCALE
const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiInputBase-root': {
      transition: 'transform 0.2s ease',
      '&:focus-within': {
        transform: 'scale(1.02)'
      }
    }
  }
}));

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Determine if we're on a mobile device
  const isMobile = useMediaQuery('(max-width:600px)');

  // Extract user state from Redux
  const {
    isLoggedIn,
    loading,
    hasErrors,
    errorMsg,
    hasCheckedIfAlreadyLoggedIn
  } = useSelector((state) => state.userSlice);

  // Local state for the form
  const [formState, setFormState] = React.useState({
    username: '',
    password: ''
  });

  const classes = useStyles();

  // If logged in, redirect to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [history, isLoggedIn]);

  // Check if already logged in on mount
  useEffect(() => {
    if (!hasCheckedIfAlreadyLoggedIn) {
      dispatch(checkIsAlreadyLoggedIn());
    }
  }, [dispatch, hasCheckedIfAlreadyLoggedIn]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = formState;
    dispatch(fetchLogin({ username, password }));
  };

  // Handle input changes
  const handleChange = (e) => {
    if (e.target.name === 'username') {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value.toLowerCase()
      });
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  };

  return (
    <PageContainer>
      <Heading>Bryan Gottschalk</Heading>
      <SubHeading>Software Engineer</SubHeading>

      <FormCard>
        {isLoggedIn ? (
          <div style={{ color: 'white' }} role="alert">
            Success! You will be redirected shortly.
          </div>
        ) : (
          <>
            <Form onSubmit={handleSubmit}>
              <Container>
                <TextField
                  className={classes.inputRoot}
                  inputProps={{
                    style: {
                      textTransform: 'lowercase',
                      background: '#EDEDED',
                      color: 'black'
                    }
                  }}
                  fullWidth
                  onChange={handleChange}
                  label="Username"
                  id="username"
                  name="username"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#EDEDED' }
                  }}
                />
              </Container>

              <Container>
                <TextField
                  className={classes.inputRoot}
                  inputProps={{
                    style: {
                      background: '#EDEDED',
                      color: 'black'
                    }
                  }}
                  fullWidth
                  variant="standard"
                  onChange={handleChange}
                  label="Password"
                  name="password"
                  type="password"
                  id="password"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#EDEDED' }
                  }}
                />
              </Container>

              <Container>
                <StyledButton
                  color="primary"
                  aria-label="Log in"
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  LOG IN
                </StyledButton>
              </Container>
            </Form>
          </>
        )}
      </FormCard>
      {loading && (
        <>
          {/* For screen readers */}
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Logging in
          </span>
          <CircularProgress
            style={{
              margin: 16,
              color: 'white',
              position: 'absolute',
              bottom: isMobile ? 100 : 300
            }}
          />
          {hasErrors && errorMsg && (
            <p style={{ color: 'white', padding: 16, marginTop: 16 }}>
              {errorMsg}
            </p>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Login;
